import { render, staticRenderFns } from "./menuOperacion.vue?vue&type=template&id=da8b3ad8&"
import script from "./menuOperacion.vue?vue&type=script&lang=js&"
export * from "./menuOperacion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QIcon,QMenu});
